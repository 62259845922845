import { ComponentController } from 'Shared/Helper/Stimulus/ComponentController';
import { findOne} from 'Shared/Helper/Dom/Dom';

const NAME = 'listing-search-page';

const SELECTORS = {
    'masthead': '.masthead',
    'footer': '.footer',
    'mailAlertButton': '.search-controls__button.search-controls__button--mail-alert',
};

const LOCAL_STORAGE_KEY = 'mailAlertDialogueOpenedByScroll';

class SearchPage extends ComponentController {
    initialize () {
        super.initialize();

        this.bindMethodsToSelf([
            'checkScrollPosition',
        ]);

        const dontShowUntilDate = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (!dontShowUntilDate || new Date(dontShowUntilDate) <= new Date()) {
            document.addEventListener('scroll', this.checkScrollPosition);
        }
    }

    checkScrollPosition () {
        const scrollPosition = window.scrollY;
        const mastheadHeight = findOne(SELECTORS.masthead).offsetHeight;
        const footerHeight = findOne(SELECTORS.footer).offsetHeight;
        const scrollableHeight = document.documentElement.scrollHeight - mastheadHeight - footerHeight;
        const viewportHeight = window.innerHeight;

        if (scrollPosition >= (scrollableHeight / 2) - (viewportHeight / 2) - mastheadHeight) {
            const mailAlertButton = document.querySelector(SELECTORS.mailAlertButton);
            if (mailAlertButton) {
                mailAlertButton.click();
                const oneWeek = 604800000;
                const dontShowUntilDateString = new Date(Date.now() + oneWeek).toISOString();

                localStorage.setItem(LOCAL_STORAGE_KEY, dontShowUntilDateString);
            }

            document.removeEventListener('scroll', this.checkScrollPosition);
        }
    }
}

export default {
    'name': NAME,
    'controller': SearchPage,
};
