// libs
import { Application } from 'stimulus';

// shared components
import cancelSubscription from 'Shared/Component/Dialog/Presentation/CancelSubscription/CancelSubscription';
import form from 'Shared/Component/Form/Form';
import searchControls from 'Shared/Component/SearchControls/Presentation/SearchControls';
import textControl from 'Shared/Component/TextControl/TextControl';
import searchList from 'Shared/Component/SearchList/SearchList';

import mailAlertFromSearch from 'Shared/Component/Dialog/MailAlertFromSearch/MailAlertFromSearch';
import deleteAccountDialog from 'Shared/Component/Dialog/Presentation/DeleteAccount/DeleteAccount';
import listingContactInfo from 'Shared/Component/ListingContactInfo/Presentation/ListingContactInfo';
import listingDetailDescription from 'Shared/Component/ListingDetailDescription/ListingDetailDescription';
import notification from 'Shared/Component/Notification/Notification';
import listingSearchItem from 'Shared/Component/ListingSearchItem/ListingSearchItem';

// Rentaroof pages
import accountOverviewPage from 'Rentaroof/Page/Account/Overview/Presentation/Overview';
import manageSubscriptionPage from 'Huurwoningen/Page/Account/Subscription/Presentation/Manage';
import listingDetailPage from 'Rentaroof/Page/Listing/Detail/Presentation/Detail';
import listingSearchPage from 'Rentaroof/Page/Listing/Search/Presentation/Search';

// Rentaroof components
import reviewForm from 'Rentaroof/Component/Form/Presentation/Review';
import reportAProblemForm from 'Rentaroof/Component/Form/Presentation/ReportAProblem';

const controllers = [
    accountOverviewPage,
    cancelSubscription,
    deleteAccountDialog,
    form,
    listingContactInfo,
    listingDetailDescription,
    listingDetailPage,
    listingSearchPage,
    mailAlertFromSearch,
    manageSubscriptionPage,
    notification,
    reportAProblemForm,
    reviewForm,
    searchControls,
    searchList,
    textControl,
    listingSearchItem,
];

window.stimulusApplication = Application.start();

controllers.forEach(controllerModule => {
    window.stimulusApplication.register(controllerModule.name, controllerModule.controller);
});
