import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { ComponentController } from 'Shared/Helper/Stimulus/ComponentController';

const NAME = 'notification';

const CLASSES = {
    'notification': renderClasses(NAME),
    'notificationHidden': renderClasses(NAME, null, ['hidden']),
    'notificationAnimated': renderClasses(NAME, null, ['animated']),
    'notificationClose': renderClasses(NAME, null, ['close']),
};

const MESSAGE_HANDLERS = [
    'geolocatorPermissionDenied.all',
    'geolocatorPositionUnavailable.all',
    'geolocatorTimeout.all',
];

class Notification extends ComponentController {
    initialize () {
        this.onCloseClick = this.onCloseClick.bind(this);

        super.initialize();
    }

    onCloseClick () {
        this.close();
    }

    onGeolocatorPermissionDenied (evt) {
        if (evt.data.data.scope == this.messagesScope) {
            this.show();
        }
    }

    onGeolocatorPositionUnavailable (evt) {
        if (evt.data.data.scope == this.messagesScope) {
            this.show();
        }
    }

    onGeolocatorTimeout (evt) {
        if (evt.data.data.scope == this.messagesScope) {
            this.show();
        }
    }

    close () {
        if (!this.element.classList.contains(CLASSES.notificationAnimated)) {
            this.element.classList.add(CLASSES.notificationHidden);

            this.messageBus.postMessage({
                'message': 'notificationHidden',
            });

            return;
        }

        this.element.addEventListener('animationend', () => {
            this.element.parentNode.removeChild(this.element);

            this.messageBus.postMessage({
                'message': 'notificationHidden',
            });
        });

        this.element.classList.add(CLASSES.notificationClose);
    }

    show () {
        this.element.classList.remove(CLASSES.notificationHidden);
    }

    get componentName () {
        return NAME;
    }

    get messageHandlers () {
        return MESSAGE_HANDLERS;
    }
}

export default {
    'name': NAME,
    'controller': Notification,
};

