import form from 'Shared/Component/Form/Form';
import { findOne } from 'Shared/Helper/Dom/Dom';

const CLASSES = {
    'formExplanation': 'form__explanation',
};

const SELECTORS = {
    'formExplanation': `.${CLASSES.formExplanation}`,
};

class ReportAProblem extends form.controller {
    initialize () {
        super.initialize();

        this.bindMethodsToSelf([
            'onFormChange',
        ]);
    }

    connect () {
        super.connect();

        this.element.addEventListener('change', this.onFormChange);
    }

    onFormChange () {
        const issue = this.element.elements['report_a_problem_form[issue]'].value;
        const explanation = findOne(SELECTORS.formExplanation, this.element);

        explanation.hidden = issue !== 'other';
    }
}

export default {
    'name': 'report-a-problem-form',
    'controller': ReportAProblem,
};
