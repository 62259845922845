import { ComponentController } from 'Shared/Helper/Stimulus/ComponentController';

const NAME = 'account-overview-page';

class OverviewPage extends ComponentController {
    onDeleteAccountClick () {
        this.messageBus.postMessage({
            'message': 'deleteAccountClicked',
        });
    }
}

export default {
    'name': NAME,
    'controller': OverviewPage,
};
