function waitForEventEnd (cb, timeout) {
    let timeoutId;

    return function (...args) {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            cb(...args);
        }, timeout);
    };
}

export { waitForEventEnd };
