import form from 'Shared/Component/Form/Form';
import { findOne } from 'Shared/Helper/Dom/Dom';

const CLASSES = {
    'formReview': 'form__review',
    'rating': 'form__rating',
};

const SELECTORS = {
    'formReview': `.${CLASSES.formReview}`,
    'review': 'textarea',
    'rating': `.${CLASSES.rating}`,
    'submitBtn': '[type=submit]',
};

class Review extends form.controller {
    initialize () {
        super.initialize();

        this.bindMethodsToSelf([
            'onFormChange',
        ]);

        const reviewLabelsJson = findOne('[type="text/json"]', this.element);
        this.reviewLabels = JSON.parse(reviewLabelsJson.textContent);
    }

    connect () {
        super.connect();

        this.element.addEventListener('change', this.onFormChange);
        this.element.addEventListener('input', this.onTextAreaInput);

        this.validateRating();
        this.updateReviewLabel();
    }

    onFormChange () {
        this.validateRating();
        this.showReview();
    }

    updateReviewLabel () {
        const ratingValue = this.getRatingValue();
        const review = findOne(SELECTORS.review, this.element);
        const label = findOne(`label[for="${review.id}"]`, this.element);
        let newLabel;

        if (ratingValue < 3) {
            newLabel = this.reviewLabels['stars-1-2'];
        } else if (ratingValue > 3) {
            newLabel = this.reviewLabels['stars-4-5'];
        } else {
            newLabel = this.reviewLabels['stars-3'];
        }

        // manually add the * to the label
        label.textContent = `${newLabel}*`;
    }

    validateRating () {
        const ratingValue = this.getRatingValue();
        const submitBtn = findOne(SELECTORS.submitBtn, this.element);

        if (ratingValue > 0) {
            submitBtn.disabled = false;
        }
    }

    showReview () {
        const ratingValue = this.getRatingValue();
        const formReview = findOne(SELECTORS.formReview, this.element);

        formReview.hidden = ratingValue === 0;
    }

    getRatingValue () {
        return Number(this.element.elements['review_form[rating]'].value);
    }
}

export default {
    'name': 'review-form',
    'controller': Review,
};
